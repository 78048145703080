<template>
	<div>
		<!-- <div class="page-top-box"> -->
		    <el-tabs v-model="activeName" @tab-click="clickEvent">
		        <el-tab-pane label="新增关注" name="1"></el-tab-pane>
		        <el-tab-pane label="取消关注" name="2"></el-tab-pane>
		        <el-tab-pane label="净增人数" name="3"></el-tab-pane>
				<el-tab-pane label="累积人数" name="4"></el-tab-pane>
		    </el-tabs>
		<!-- </div> -->
		<div class="page-content-box">
			<div class="markRed" v-if="activeName == 1 ">新增关注：新关注的粉丝人数</div>
			<div class="markRed" v-if="activeName == 2 ">取消关注：取消关注的粉丝人数</div>
			<div class="markRed" v-if="activeName == 3 ">净增人数：新关注的粉丝人数减去取消关注的粉丝人数后得到的人数（有可能是负数）</div>
			<div class="markRed" v-if="activeName == 4 ">累积人数：当前关注的粉丝人数</div>
			<div id="fansChart"></div>
			
			<div class="page-list-box">
			    <el-table
			        :data="dataList"
			        header-row-class-name="page-list-table"
			        style="width: 100%"
			        v-loading="listLoading"
			        size="small"
			        stripe
			    >
					<el-table-column prop="time" label="日期"></el-table-column>
					<el-table-column prop="newCollectNum" label="新增关注"></el-table-column>
					<el-table-column prop="cancelCollectNum" label="取消关注"></el-table-column>
					<el-table-column prop="growthCollectNum" label="净增人数"></el-table-column>
					<el-table-column prop="totalCollectNum" label="累积人数"></el-table-column>					
				</el-table>
			</div>
			<div v-if="data" class="page-pagination-box">
			    <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="getFanlist"
			        :current-page.sync="params.pageNo"
			        :page-sizes="[15, 30, 50]"
			        :page-size.sync="params.pageSize"
			        layout="sizes, prev, pager, next"
			        :total="data.totalCount || 0">
			    </el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
import { Chart } from '@antv/g2';

export default {
    components: { },
    name: "dashboard",
    data() {
        return {
            dataLoading: false,
			listLoading: false,
            interval: [],
            activeName: '1',
            intervalData: null,
			params:{
			    pageNo:parseInt(this.$route.query.pageNo) || 1,
			    pageSize: parseInt(this.$route.query.pageSize) || 15,			    
			},
			dataChart:null,
			data:null,
			dataList: null,
			chartTitle:''
        };
    },
    computed:{
        
    },
    created(){
        this.getdataFan();
		this.getFanlist();
    },
	
	watch:{
	   //  intervalData(v1,v2){
	   //      if(v1 != v2){
	   //          this.gethostChart();
				// this.getHostList();
	   //      }
	   //  }
	},
    methods:{
        //获取粉丝增长数据 线图
        getdataFan(){           
            this.$request({
                url: "/report/collectLineInfo",
                method: "GET",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
						if( this.activeName == 1 ){
							this.dataChart = result.newLineList
						}
						if( this.activeName == 2 ){
							this.dataChart = result.cancelLineList
						}
						if( this.activeName == 3 ){
							this.dataChart = result.growthLineList
						}
						if( this.activeName == 4 ){
							this.dataChart = result.totalLineList
						}
						this.$nextTick(() => {
						    this.drawChart();						    
						})
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        clickEvent(tab){
            this.getdataFan();
        },		
		drawChart(){
		    if( this.chart ) this.chart.destroy();
		    
		    let _data = [];
		    const _dataArry = [...this.dataChart];
		    
		    _dataArry.map( item => {
		        _data.push({
		            name: item.name,
		            value: parseFloat(item.value)
		        })
		    })
		
		    this.chart = new Chart({
		        container: 'fansChart',
		        autoFit: true,
		        height: 300,
		    });
		    this.chart.data(_data);
		
		    this.chart.tooltip({
		        showTitle: true,
		        showMarkers: false,
		        showCrosshairs: true,
		    });
		    this.chart.legend('name', {
		        position: 'top',
		    });
		    this.chart
		        .line()
		        .position('name*value')		        
		        .shape('smooth')
				.tooltip('name*value*percent', (name, value) => {
					if( this.activeName == 1 ){
						this.chartTitle = '新增关注'
					}
					if( this.activeName == 2 ){
						this.chartTitle = '取消关注'
					}
					if( this.activeName == 3 ){
						this.chartTitle = '净增人数'
					}
					if( this.activeName == 4 ){
						this.chartTitle = '累积人数'
					}
				    return {
				        name: this.chartTitle,
				        value: value,
				    };
				});
				
		    this.chart.render();
		    
		},
		//主办销售活动线图下面的列表
		getFanlist(){
			this.$request({
			    url: "/report/collectPageList",
			    method: "POST",				
				data: {...this.params},
			})
			    .then((res) => {
			        const { state, msg, result } = res.data;
			        if (state == 1 && result) {
					   this.data = result
			           this.dataList = result.result
			        } else {
			            this.$message.error(msg || "请求失败");
			        }
			    })
			    .catch(() => {
			        this.$message.error("请求错误");
			    })
			    .finally(() => {
			        
			    });
		},
		//选择pageSize
		handleSizeChange(e) {
		    this.params.pageNo = 1;
		    this.params.pageSize = e;
		    this.getHostList();
		},
		
    }
};
</script>

<style lang="scss" scoped>
// .page-top-box{
// 	background: #fff;
// }
// .page-tab-box{
//     margin: -20px -20px 20px -20px;
//     padding: 0 20px;
//     border-bottom: 1px #E4E7ED solid;
//     ::v-deep .el-tabs__header{
//         border-bottom: 0;
//         margin: 0;
//     }
// }
.data-group{
    > ::v-deep .el-card__header{
        font-size: 16px;
        line-height: 22px;
        a{
            font-size: 14px;
        }
    }
}
#fansChart{
	margin-bottom: 40px;
}
.markRed{
	padding: 0 0 40px 0;
	color: #f00;
}
</style>